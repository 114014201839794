import { useWindowSize } from "@uidotdev/usehooks";
import { deviceWidthEnum } from "../helpers";
import { Link } from "react-router-dom";
import { useSummaryContext } from "../context";
import { ContactCard } from "./ContactCard";

const FooterLinks = () => {
    return (
        <>
            <Link to="/">Terms</Link>
            <Link to="/">Privacy</Link>
            <div className="dropdown dropdown-top dropdown-end">
                <div tabIndex={0} role="button" className="opacity">
                    Contact
                </div>
                <div tabIndex={0} className="dropdown-content z-[1] menu mb-5 p-2 text-left shadow bg-gray-100 dark:bg-base-100 rounded-box">
                    <div className="flex px-4 py-2">
                        <div className="grow space-y-4">
                            <h3 className="text-gray-800 dark:text-white">
                                Contact:
                            </h3>
                            <div className="inline-flex items-center gap-x-3 font-semibold text-sm text-gray-800 dark:text-neutral-200">
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="20" height="16" x="2" y="4" rx="2" /><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" /></svg>
                                <a href={"mailto:summary.create@gmail.com"} className="underline">summary.create@gmail.com</a>
                            </div>
                            <div className="inline-flex items-center gap-x-3 font-semibold text-sm text-gray-800 dark:text-neutral-200">
                                <svg className="flex-shrink-0 w-4 h-4 text-gray-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="14" height="20" x="5" y="2" rx="2" ry="2" /><path d="M12 18h.01" /></svg>
                                <a href={"tel:2349137823897"}>(234) 913-7823-897</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export const EmptyPageFooter = () => {
    const size = useWindowSize();

    if (size?.width < deviceWidthEnum.laptop) {
        return (
            <section>
                <footer className="footer place-items-center md:place-items-start mx-auto px-10 py-4 border-t bg-gray-100/80 dark:bg-base-200 text-center text-base-content border-base-300 lg:px-32">
                    <aside>
                        <p>Copyright © {new Date().getFullYear()} - All right reserved by <strong>Summary</strong></p>
                    </aside>
                    <nav className="md:place-self-center md:justify-self-end">
                        <div className="grid grid-flow-col gap-4">
                            <FooterLinks />
                        </div>
                    </nav>
                </footer>
            </section>
        );
    } else if (size?.width >= deviceWidthEnum.laptop && size?.width < deviceWidthEnum.desktop) {
        // For desktop size - 1024px
        return (
            <section>
                <footer className="footer px-10 py-4 border-t bg-gray-100 dark:bg-base-200/80 text-base-content border-base-300 lg:px-8">
                    <aside>
                        <p>Copyright © {new Date().getFullYear()} - All right reserved by <strong>Summary</strong></p>
                    </aside>
                    <nav className="md:place-self-center md:justify-self-end">
                        <div className="grid grid-flow-col gap-4">
                            <FooterLinks />
                        </div>
                    </nav>
                </footer>
            </section>
        );
    }

    return (
        <section>
            <footer className="footer px-10 py-4 border-t bg-gray-100 dark:bg-base-200/80 text-base-content border-base-300 lg:px-32">
                <aside>
                    <p>Copyright © {new Date().getFullYear()} - All right reserved by <strong>Summary</strong></p>
                </aside>
                <nav className="md:place-self-center md:justify-self-end">
                    <div className="grid grid-flow-col gap-4">
                        <FooterLinks />
                    </div>
                </nav>
            </footer>
        </section>
        // <footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300">
        //     <aside className="items-center grid-flow-col">
        //         <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg>
        //         <p>ACME Industries Ltd. <br />Providing reliable tech since 1992</p>
        //     </aside>
        //     <nav className="md:place-self-center md:justify-self-end">
        //         <div className="grid grid-flow-col gap-4">
        //             <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a>
        //             <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
        //             <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
        //         </div>
        //     </nav>
        // </footer>
    );
}

const Footer = () => {
    const size = useWindowSize();
    const { baseData, isFetchingSummary, eventOpened } = useSummaryContext();

    if (size?.width < deviceWidthEnum.laptop) {
        return (
            <section>
                {
                    !(isFetchingSummary || eventOpened) && baseData.data?.length > 0
                    && <footer className="footer relative items-center p-4 border-t bg-gray-100/60 dark:border-base-100 dark:bg-base-200 text-base-content w-full lg:px-32">
                        <aside className="items-center grid-flow-col w-full mx-auto lg:w-auto lg:mx-0">
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current hidden lg:block"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg> */}
                            <div className="w-full mx-auto text-center py-2 space-y-2 lg:w-auto lg:mx-0 lg:text-left">
                                <div className="font-bold text-xl">Summary</div>
                                {/* <div>Copyright © {new Date().getFullYear()}</div>
                        <div>All right reserved by Summary</div> */}
                                {/* <div className="text-xs">Powered by Google Search</div> */}
                                <div className="text-xs">Be productive</div>
                            </div>
                        </aside>
                        <nav className="hidden lg:grid-flow-col gap-4 md:place-self-center md:justify-self-end">
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
                            </a>
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                        </nav>
                    </footer>
                }
                {
                    !(isFetchingSummary || eventOpened) && baseData.data?.length > 0
                    && <footer className="footer place-items-center md:place-items-start mx-auto px-10 py-4 border-t bg-gray-100/80 dark:bg-base-200 text-center text-base-content border-base-300 lg:px-32">
                        <aside>
                            <p>Copyright © {new Date().getFullYear()} - All right reserved by <strong>Summary</strong></p>
                        </aside>
                        <nav className="md:place-self-center md:justify-self-end">
                            <div className="grid grid-flow-col gap-4">
                                <FooterLinks />
                            </div>
                        </nav>
                    </footer>
                }
            </section>
        );
    } else if (size?.width >= deviceWidthEnum.laptop && size?.width < deviceWidthEnum.desktop) {
        // For desktop size - 1024px
        return (
            <section>
                {
                    !(isFetchingSummary || eventOpened) && baseData.data?.length > 0
                    && <footer className="footer relative items-center p-4 border-t dark:border-base-100 bg-gray-100 dark:bg-base-200/80 text-base-content w-full lg:px-8">
                        <aside className="items-center grid-flow-col w-full mx-auto lg:w-auto lg:mx-0">
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current hidden lg:block"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg> */}
                            <div className="w-full mx-auto text-center py-2 space-y-2 lg:w-auto lg:mx-0 lg:text-left">
                                <div className="font-bold text-xl">Summary</div>
                                {/* <div>Copyright © {new Date().getFullYear()}</div>
                        <div>All right reserved by Summary</div> */}
                                {/* <div className="text-xs">Powered by Google Search</div> */}
                                <div className="text-xs">Be productive</div>
                            </div>
                        </aside>
                        <nav className="hidden lg:grid-flow-col gap-4 md:place-self-center md:justify-self-end">
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
                            </a>
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                        </nav>
                    </footer>
                }
                {
                    !(isFetchingSummary || eventOpened) && baseData.data?.length > 0
                    && <footer className="footer px-10 py-4 border-t bg-gray-100 dark:bg-base-200/80 text-base-content border-base-300 lg:px-8">
                        <aside>
                            <p>Copyright © {new Date().getFullYear()} - All right reserved by <strong>Summary</strong></p>
                        </aside>
                        <nav className="md:place-self-center md:justify-self-end">
                            <div className="grid grid-flow-col gap-4">
                                <FooterLinks />
                            </div>
                        </nav>
                    </footer>
                }
            </section>
        );
    }

    return (
        <section>
            {
                !(isFetchingSummary || eventOpened) && baseData.data?.length > 0
                && <footer className="footer relative items-center p-4 border-t dark:border-base-100 bg-gray-100 dark:bg-base-200/80 text-base-content w-full lg:px-32">
                    <aside className="items-center grid-flow-col w-full mx-auto lg:w-auto lg:mx-0">
                        {/* <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current hidden lg:block"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg> */}
                        <div className="w-full mx-auto text-center py-2 space-y-2 lg:w-auto lg:mx-0 lg:text-left">
                            <div className="font-bold text-xl">Summary</div>
                            {/* <div>Copyright © {new Date().getFullYear()}</div>
                        <div>All right reserved by Summary</div> */}
                            {/* <div className="text-xs">Powered by Google Search</div> */}
                            <div className="text-xs">Be productive</div>
                        </div>
                    </aside>
                    <nav className="hidden lg:grid-flow-col gap-4 md:place-self-center md:justify-self-end">
                        <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
                        </a>
                        <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
                        <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                    </nav>
                </footer>
            }
            {
                !(isFetchingSummary || eventOpened) && baseData.data?.length > 0
                && <footer className="footer px-10 py-4 border-t bg-gray-100 dark:bg-base-200/80 text-base-content border-base-300 lg:px-32">
                    <aside>
                        <p>Copyright © {new Date().getFullYear()} - All right reserved by <strong>Summary</strong></p>
                    </aside>
                    <nav className="md:place-self-center md:justify-self-end">
                        <div className="grid grid-flow-col gap-4">
                            <FooterLinks />
                        </div>
                    </nav>
                </footer>
            }
        </section>
        // <footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300">
        //     <aside className="items-center grid-flow-col">
        //         <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg>
        //         <p>ACME Industries Ltd. <br />Providing reliable tech since 1992</p>
        //     </aside>
        //     <nav className="md:place-self-center md:justify-self-end">
        //         <div className="grid grid-flow-col gap-4">
        //             <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a>
        //             <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
        //             <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
        //         </div>
        //     </nav>
        // </footer>
    );
}

export default Footer;